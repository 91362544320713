import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <iframe title="contact"
      src="https://docs.google.com/forms/d/e/1FAIpQLSenEL6kzgVozzImKtpO_HkpqxbeNPhaVrdHYTSxqC4Ufr_JPw/viewform?embedded=true"
      width="850" height="751" frameBorder="0" marginHeight="0" marginWidth="0">Loading...
    </iframe>
  </Layout>
)
